import React from "react"
import RacePageOneDay2022 from "../components/racepageOneDay2022"

const Race2022 = () => {
  const startlist = {
    info: {
      providedBy: "FirstCycling.com",
      race: "Paris - Roubaix",
      edition: 2022,
      what: "Race data",
      updated: "2022-04-15 13:22:14",
      linkRiders: "https://firstcycling.com/rider.php?r={id}",
      linkTeams: "https://firstcycling.com/team.php?l={teamId}",
    },
    teams: {
      21801: {
        teamId: "21801",
        teamUciCode: "ACT",
        teamName: "AG2R Citro\u00c3\u00abn Team",
        teamNationCode: "FRA",
      },
      21803: {
        teamId: "21803",
        teamUciCode: "AST",
        teamName: "Astana Qazaqstan Team",
        teamNationCode: "KAZ",
      },
      21804: {
        teamId: "21804",
        teamUciCode: "TBV",
        teamName: "Bahrain Victorious",
        teamNationCode: "BHR",
      },
      21809: {
        teamId: "21809",
        teamUciCode: "QST",
        teamName: "Quick-Step Alpha Vinyl Team",
        teamNationCode: "BEL",
      },
      21812: {
        teamId: "21812",
        teamUciCode: "EFE",
        teamName: "EF Education-EasyPost",
        teamNationCode: "USA",
      },
      21814: {
        teamId: "21814",
        teamUciCode: "GFC",
        teamName: "Groupama-FDJ",
        teamNationCode: "FRA",
      },
      21818: {
        teamId: "21818",
        teamUciCode: "ISN",
        teamName: "Israel-Premier Tech",
        teamNationCode: "ISR",
      },
      21820: {
        teamId: "21820",
        teamUciCode: "LTS",
        teamName: "Lotto Soudal",
        teamNationCode: "BEL",
      },
      21823: {
        teamId: "21823",
        teamUciCode: "MOV",
        teamName: "Movistar Team",
        teamNationCode: "ESP",
      },
      21827: {
        teamId: "21827",
        teamUciCode: "IGD",
        teamName: "INEOS Grenadiers",
        teamNationCode: "GBR",
      },
      21830: {
        teamId: "21830",
        teamUciCode: "TJV",
        teamName: "Jumbo-Visma",
        teamNationCode: "NED",
      },
      21833: {
        teamId: "21833",
        teamUciCode: "DSM",
        teamName: "Team DSM",
        teamNationCode: "NED",
      },
      21837: {
        teamId: "21837",
        teamUciCode: "TFS",
        teamName: "Trek-Segafredo",
        teamNationCode: "USA",
      },
      21839: {
        teamId: "21839",
        teamUciCode: "UAD",
        teamName: "UAE Team Emirates",
        teamNationCode: "UAE",
      },
      21843: {
        teamId: "21843",
        teamUciCode: "UXT",
        teamName: "Uno-X Pro Cycling Team",
        teamNationCode: "NOR",
      },
      21845: {
        teamId: "21845",
        teamUciCode: "AFC",
        teamName: "Alpecin-Fenix",
        teamNationCode: "BEL",
      },
      21850: {
        teamId: "21850",
        teamUciCode: "BBK",
        teamName: "B&B Hotels-KTM",
        teamNationCode: "FRA",
      },
      21856: {
        teamId: "21856",
        teamUciCode: "IWG",
        teamName: "Intermarch\u00c3\u00a9-Wanty-Gobert Mat\u00c3\u00a9riaux",
        teamNationCode: "BEL",
      },
      21865: {
        teamId: "21865",
        teamUciCode: "ARK",
        teamName: "Team Ark\u00c3\u00a9a-Samsic",
        teamNationCode: "FRA",
      },
      21868: {
        teamId: "21868",
        teamUciCode: "TEN",
        teamName: "TotalEnergies",
        teamNationCode: "FRA",
      },
      22245: {
        teamId: "22245",
        teamUciCode: "BOH",
        teamName: "BORA-hansgrohe",
        teamNationCode: "GER",
      },
      22290: {
        teamId: "22290",
        teamUciCode: "COF",
        teamName: "Cofidis",
        teamNationCode: "FRA",
      },
      22480: {
        teamId: "22480",
        teamUciCode: "BEX",
        teamName: "Team BikeExchange-Jayco",
        teamNationCode: "AUS",
      },
      23137: {
        teamId: "23137",
        teamUciCode: "BWB",
        teamName: "Bingoal Pauwels Sauces WB",
        teamNationCode: "BEL",
      },
      23398: {
        teamId: "23398",
        teamUciCode: "SVB",
        teamName: "Sport Vlaanderen-Baloise",
        teamNationCode: "BEL",
      },
    },
    riders: {
      4927: {
        id: 4927,
        startno: 136,
        firstName: "Kenneth",
        lastName: "Vanbilsen",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1990-06-01",
        teamId: 22290,
        teamName: "Cofidis",
        stillInTheRace: "Y",
      },
      16793: {
        id: 16793,
        startno: 101,
        firstName: "Mads",
        lastName: "Pedersen",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1995-12-18",
        teamId: 21837,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      41368: {
        id: 41368,
        startno: 137,
        firstName: "Alexandre",
        lastName: "Delettre",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1997-10-25",
        teamId: 22290,
        teamName: "Cofidis",
        stillInTheRace: "Y",
      },
      780: {
        id: 780,
        startno: 131,
        firstName: "Davide",
        lastName: "Cimolai",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1989-08-13",
        teamId: 22290,
        teamName: "Cofidis",
        stillInTheRace: "Y",
      },
      6557: {
        id: 6557,
        startno: 167,
        firstName: "Lukasz",
        lastName: "Wisniowski",
        nationCode: "POL",
        nationName: "Poland",
        birthDate: "1991-12-07",
        teamId: 21812,
        teamName: "EF Education-EasyPost",
        stillInTheRace: "Y",
      },
      90911: {
        id: 90911,
        startno: 65,
        firstName: "Taj",
        lastName: "Jones",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "2000-07-26",
        teamId: 21818,
        teamName: "Israel-Premier Tech",
        stillInTheRace: "Y",
      },
      1966: {
        id: 1966,
        startno: 222,
        firstName: "Jens",
        lastName: "Debusschere",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1989-08-28",
        teamId: 21850,
        teamName: "B&B Hotels-KTM",
        stillInTheRace: "Y",
      },
      11828: {
        id: 11828,
        startno: 211,
        firstName: "Iv\u00c3\u00a1n",
        lastName: "Garc\u00c3\u00ada Cortina",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1995-11-20",
        teamId: 21823,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      15686: {
        id: 15686,
        startno: 163,
        firstName: "Owain",
        lastName: "Doull",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1993-02-05",
        teamId: 21812,
        teamName: "EF Education-EasyPost",
        stillInTheRace: "Y",
      },
      11313: {
        id: 11313,
        startno: 55,
        firstName: "Mike",
        lastName: "Teunissen",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1992-08-25",
        teamId: 21830,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
      },
      2294: {
        id: 2294,
        startno: 63,
        firstName: "Reto",
        lastName: "Hollenstein",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1985-08-22",
        teamId: 21818,
        teamName: "Israel-Premier Tech",
        stillInTheRace: "Y",
      },
      9014: {
        id: 9014,
        startno: 2,
        firstName: "Chun Kai",
        lastName: "Feng",
        nationCode: "TPE",
        nationName: "Taiwan",
        birthDate: "1988-11-02",
        teamId: 21804,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
      },
      69912: {
        id: 69912,
        startno: 11,
        firstName: "Florian",
        lastName: "Vermeersch",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1999-03-12",
        teamId: 21820,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
      },
      54613: {
        id: 54613,
        startno: 244,
        firstName: "Alex",
        lastName: "Colman",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1998-07-22",
        teamId: 23398,
        teamName: "Sport Vlaanderen-Baloise",
        stillInTheRace: "Y",
      },
      65604: {
        id: 65604,
        startno: 95,
        firstName: "Antoine",
        lastName: "Raugel",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1999-02-14",
        teamId: 21801,
        teamName: "AG2R Citro\u00c3\u00abn Team",
        stillInTheRace: "Y",
      },
      16817: {
        id: 16817,
        startno: 143,
        firstName: "Nico",
        lastName: "Denz",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1994-02-15",
        teamId: 21833,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      312: {
        id: 312,
        startno: 161,
        firstName: "Sebastian",
        lastName: "Langeveld",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1985-01-17",
        teamId: 21812,
        teamName: "EF Education-EasyPost",
        stillInTheRace: "Y",
      },
      10971: {
        id: 10971,
        startno: 41,
        firstName: "Yves",
        lastName: "Lampaert",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1991-04-10",
        teamId: 21809,
        teamName: "Quick-Step Alpha Vinyl Team",
        stillInTheRace: "Y",
      },
      3051: {
        id: 3051,
        startno: 25,
        firstName: "Guillaume",
        lastName: "Van Keirsbulck",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1991-02-14",
        teamId: 21845,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
      },
      10871: {
        id: 10871,
        startno: 114,
        firstName: "Olivier",
        lastName: "Le Gac",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1993-08-27",
        teamId: 21814,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
      },
      3096: {
        id: 3096,
        startno: 33,
        firstName: "Manuele",
        lastName: "Boaro",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1987-12-03",
        teamId: 21803,
        teamName: "Astana Qazaqstan Team",
        stillInTheRace: "Y",
      },
      406: {
        id: 406,
        startno: 171,
        firstName: "Alexander",
        lastName: "Kristoff",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1987-07-05",
        teamId: 21856,
        teamName: "Intermarch\u00c3\u00a9-Wanty-Gobert Mat\u00c3\u00a9riaux",
        stillInTheRace: "Y",
      },
      10382: {
        id: 10382,
        startno: 106,
        firstName: "Edward",
        lastName: "Theuns",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1991-04-30",
        teamId: 21837,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      34624: {
        id: 34624,
        startno: 204,
        firstName: "Sebastian",
        lastName: "Molano",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1994-11-04",
        teamId: 21839,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
      },
      16797: {
        id: 16797,
        startno: 224,
        firstName: "Quentin",
        lastName: "Jauregui",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1994-04-22",
        teamId: 21850,
        teamName: "B&B Hotels-KTM",
        stillInTheRace: "Y",
      },
      3147: {
        id: 3147,
        startno: 173,
        firstName: "Andrea",
        lastName: "Pasqualon",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1988-01-02",
        teamId: 21856,
        teamName: "Intermarch\u00c3\u00a9-Wanty-Gobert Mat\u00c3\u00a9riaux",
        stillInTheRace: "Y",
      },
      7910: {
        id: 7910,
        startno: 166,
        firstName: "Thomas",
        lastName: "Scully",
        nationCode: "NZL",
        nationName: "New Zealand",
        birthDate: "1990-01-14",
        teamId: 21812,
        teamName: "EF Education-EasyPost",
        stillInTheRace: "Y",
      },
      920: {
        id: 920,
        startno: 164,
        firstName: "Jens",
        lastName: "Keukeleire",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1988-11-23",
        teamId: 21812,
        teamName: "EF Education-EasyPost",
        stillInTheRace: "Y",
      },
      66265: {
        id: 66265,
        startno: 201,
        firstName: "Mikkel",
        lastName: "Bjerg",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1998-11-03",
        teamId: 21839,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
      },
      2313: {
        id: 2313,
        startno: 83,
        firstName: "Luke",
        lastName: "Rowe",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1990-03-10",
        teamId: 21827,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
      },
      5836: {
        id: 5836,
        startno: 36,
        firstName: "Dmitriy",
        lastName: "Gruzdev",
        nationCode: "KAZ",
        nationName: "Kazakhstan",
        birthDate: "1986-03-13",
        teamId: 21803,
        teamName: "Astana Qazaqstan Team",
        stillInTheRace: "Y",
      },
      16687: {
        id: 16687,
        startno: 1,
        firstName: "Matej",
        lastName: "Mohoric",
        nationCode: "SLO",
        nationName: "Slovenia",
        birthDate: "1994-10-19",
        teamId: 21804,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
      },
      6246: {
        id: 6246,
        startno: 123,
        firstName: "Marco",
        lastName: "Haller",
        nationCode: "AUT",
        nationName: "Austria",
        birthDate: "1991-04-01",
        teamId: 22245,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
      },
      18574: {
        id: 18574,
        startno: 236,
        firstName: "Ludovic",
        lastName: "Robeet",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1994-05-22",
        teamId: 23137,
        teamName: "Bingoal Pauwels Sauces WB",
        stillInTheRace: "Y",
      },
      67846: {
        id: 67846,
        startno: 216,
        firstName: "Oier",
        lastName: "Lazkano",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1999-11-07",
        teamId: 21823,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      18260: {
        id: 18260,
        startno: 121,
        firstName: "Nils",
        lastName: "Politt",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1994-03-06",
        teamId: 22245,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
      },
      37495: {
        id: 37495,
        startno: 117,
        firstName: "Bram",
        lastName: "Welten",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1997-03-29",
        teamId: 21814,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
      },
      46157: {
        id: 46157,
        startno: 162,
        firstName: "Stefan",
        lastName: "Bissegger",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1998-09-13",
        teamId: 21812,
        teamName: "EF Education-EasyPost",
        stillInTheRace: "Y",
      },
      63035: {
        id: 63035,
        startno: 15,
        firstName: "S\u00c3\u00a9bastien",
        lastName: "Grignard",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1999-04-29",
        teamId: 21820,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
      },
      45371: {
        id: 45371,
        startno: 247,
        firstName: "Jens",
        lastName: "Reynders",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1998-05-25",
        teamId: 23398,
        teamName: "Sport Vlaanderen-Baloise",
        stillInTheRace: "Y",
      },
      14737: {
        id: 14737,
        startno: 53,
        firstName: "Christophe",
        lastName: "Laporte",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1992-12-11",
        teamId: 21830,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
      },
      77148: {
        id: 77148,
        startno: 144,
        firstName: "Marius",
        lastName: "Mayrhofer",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "2000-09-18",
        teamId: 21833,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      27240: {
        id: 27240,
        startno: 195,
        firstName: "Jan",
        lastName: "Maas",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1996-02-19",
        teamId: 22480,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
      },
      16583: {
        id: 16583,
        startno: 57,
        firstName: "Nathan",
        lastName: "Van Hooydonck",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1995-10-12",
        teamId: 21830,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
      },
      8068: {
        id: 8068,
        startno: 4,
        firstName: "Dylan",
        lastName: "Teuns",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1992-03-01",
        teamId: 21804,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
      },
      46959: {
        id: 46959,
        startno: 156,
        firstName: "Cl\u00c3\u00a9ment",
        lastName: "Russo",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1995-01-20",
        teamId: 21865,
        teamName: "Team Ark\u00c3\u00a9a-Samsic",
        stillInTheRace: "Y",
      },
      26504: {
        id: 26504,
        startno: 43,
        firstName: "Davide",
        lastName: "Ballerini",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1994-09-21",
        teamId: 21809,
        teamName: "Quick-Step Alpha Vinyl Team",
        stillInTheRace: "Y",
      },
      57958: {
        id: 57958,
        startno: 185,
        firstName: "Erik",
        lastName: "Resell",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1996-09-28",
        teamId: 21843,
        teamName: "Uno-X Pro Cycling Team",
        stillInTheRace: "Y",
      },
      27331: {
        id: 27331,
        startno: 132,
        firstName: "Piet",
        lastName: "Allegaert",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1995-01-20",
        teamId: 22290,
        teamName: "Cofidis",
        stillInTheRace: "Y",
      },
      16615: {
        id: 16615,
        startno: 66,
        firstName: "Mads",
        lastName: "W\u00c3\u00bcrtz Schmidt",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1994-03-31",
        teamId: 21818,
        teamName: "Israel-Premier Tech",
        stillInTheRace: "Y",
      },
      3133: {
        id: 3133,
        startno: 154,
        firstName: "Laurent",
        lastName: "Pichon",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1986-07-19",
        teamId: 21865,
        teamName: "Team Ark\u00c3\u00a9a-Samsic",
        stillInTheRace: "Y",
      },
      3933: {
        id: 3933,
        startno: 206,
        firstName: "Matteo",
        lastName: "Trentin",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1989-08-02",
        teamId: 21839,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
      },
      37294: {
        id: 37294,
        startno: 135,
        firstName: "Szymon",
        lastName: "Sajnok",
        nationCode: "POL",
        nationName: "Poland",
        birthDate: "1997-08-24",
        teamId: 22290,
        teamName: "Cofidis",
        stillInTheRace: "Y",
      },
      3095: {
        id: 3095,
        startno: 177,
        firstName: "Kevin",
        lastName: "Van Melsen",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1987-04-01",
        teamId: 21856,
        teamName: "Intermarch\u00c3\u00a9-Wanty-Gobert Mat\u00c3\u00a9riaux",
        stillInTheRace: "Y",
      },
      69317: {
        id: 69317,
        startno: 3,
        firstName: "Johan",
        lastName: "Price-Pejtersen",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1999-05-26",
        teamId: 21804,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
      },
      49662: {
        id: 49662,
        startno: 157,
        firstName: "Connor",
        lastName: "Swift",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1995-10-30",
        teamId: 21865,
        teamName: "Team Ark\u00c3\u00a9a-Samsic",
        stillInTheRace: "Y",
      },
      22133: {
        id: 22133,
        startno: 54,
        firstName: "Timo",
        lastName: "Roosen",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1993-01-11",
        teamId: 21830,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
      },
      3303: {
        id: 3303,
        startno: 22,
        firstName: "Silvan",
        lastName: "Dillier",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1990-08-03",
        teamId: 21845,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
      },
      4317: {
        id: 4317,
        startno: 116,
        firstName: "Tobias",
        lastName: "Ludvigsson",
        nationCode: "SWE",
        nationName: "Sweden",
        birthDate: "1991-02-22",
        teamId: 21814,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
      },
      22682: {
        id: 22682,
        startno: 93,
        firstName: "Oliver",
        lastName: "Naesen",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1990-09-16",
        teamId: 21801,
        teamName: "AG2R Citro\u00c3\u00abn Team",
        stillInTheRace: "Y",
      },
      75468: {
        id: 75468,
        startno: 243,
        firstName: "Vito ",
        lastName: "Braet",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "2000-11-02",
        teamId: 23398,
        teamName: "Sport Vlaanderen-Baloise",
        stillInTheRace: "Y",
      },
      37482: {
        id: 37482,
        startno: 231,
        firstName: "Karl Patrick",
        lastName: "Lauk",
        nationCode: "EST",
        nationName: "Estonia",
        birthDate: "1997-01-09",
        teamId: 23137,
        teamName: "Bingoal Pauwels Sauces WB",
        stillInTheRace: "Y",
      },
      5306: {
        id: 5306,
        startno: 142,
        firstName: "Nikias",
        lastName: "Arndt",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1991-11-18",
        teamId: 21833,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      38146: {
        id: 38146,
        startno: 146,
        firstName: "Joris",
        lastName: "Nieuwenhuis",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1996-02-11",
        teamId: 21833,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      7964: {
        id: 7964,
        startno: 27,
        firstName: "Gianni",
        lastName: "Vermeersch",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1992-11-19",
        teamId: 21845,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
      },
      37430: {
        id: 37430,
        startno: 24,
        firstName: "Senne",
        lastName: "Leysen",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1996-03-18",
        teamId: 21845,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
      },
      37397: {
        id: 37397,
        startno: 113,
        firstName: "Cl\u00c3\u00a9ment",
        lastName: "Davy",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1998-07-17",
        teamId: 21814,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
      },
      20581: {
        id: 20581,
        startno: 31,
        firstName: "Leonardo",
        lastName: "Basso",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1993-12-25",
        teamId: 21803,
        teamName: "Astana Qazaqstan Team",
        stillInTheRace: "Y",
      },
      16807: {
        id: 16807,
        startno: 102,
        firstName: "Markus",
        lastName: "Hoelgaard",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1994-10-04",
        teamId: 21837,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      5059: {
        id: 5059,
        startno: 105,
        firstName: "Jasper",
        lastName: "Stuyven",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1992-04-17",
        teamId: 21837,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      3199: {
        id: 3199,
        startno: 64,
        firstName: "Hugo",
        lastName: "Houle",
        nationCode: "CAN",
        nationName: "Canada",
        birthDate: "1990-09-27",
        teamId: 21818,
        teamName: "Israel-Premier Tech",
        stillInTheRace: "Y",
      },
      12800: {
        id: 12800,
        startno: 223,
        firstName: "Alexis",
        lastName: "Gougeard",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1993-03-05",
        teamId: 21850,
        teamName: "B&B Hotels-KTM",
        stillInTheRace: "Y",
      },
      27099: {
        id: 27099,
        startno: 81,
        firstName: "Filippo",
        lastName: "Ganna",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1996-07-25",
        teamId: 21827,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
      },
      407: {
        id: 407,
        startno: 205,
        firstName: "Vegard Stake",
        lastName: "Laengen",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1989-02-07",
        teamId: 21839,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
      },
      13898: {
        id: 13898,
        startno: 155,
        firstName: "Christophe",
        lastName: "Noppe",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1994-11-29",
        teamId: 21865,
        teamName: "Team Ark\u00c3\u00a9a-Samsic",
        stillInTheRace: "Y",
      },
      82253: {
        id: 82253,
        startno: 184,
        firstName: "William",
        lastName: "Blume Levy",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "2001-01-14",
        teamId: 21843,
        teamName: "Uno-X Pro Cycling Team",
        stillInTheRace: "Y",
      },
      63055: {
        id: 63055,
        startno: 7,
        firstName: "Fred",
        lastName: "Wright",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1999-06-13",
        teamId: 21804,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
      },
      75603: {
        id: 75603,
        startno: 34,
        firstName: "Yevgeniy",
        lastName: "Fedorov",
        nationCode: "KAZ",
        nationName: "Kazakhstan",
        birthDate: "2000-02-16",
        teamId: 21803,
        teamName: "Astana Qazaqstan Team",
        stillInTheRace: "Y",
      },
      2961: {
        id: 2961,
        startno: 82,
        firstName: "Michal",
        lastName: "Kwiatkowski",
        nationCode: "POL",
        nationName: "Poland",
        birthDate: "1990-06-02",
        teamId: 21827,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
      },
      1867: {
        id: 1867,
        startno: 87,
        firstName: "Cameron",
        lastName: "Wurf",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1983-08-03",
        teamId: 21827,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
      },
      37996: {
        id: 37996,
        startno: 235,
        firstName: "Milan",
        lastName: "Menten",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1996-10-31",
        teamId: 23137,
        teamName: "Bingoal Pauwels Sauces WB",
        stillInTheRace: "Y",
      },
      333: {
        id: 333,
        startno: 213,
        firstName: "Imanol",
        lastName: "Erviti",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1983-11-15",
        teamId: 21823,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      47830: {
        id: 47830,
        startno: 46,
        firstName: "Jannik",
        lastName: "Steimle",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1996-04-04",
        teamId: 21809,
        teamName: "Quick-Step Alpha Vinyl Team",
        stillInTheRace: "Y",
      },
      7724: {
        id: 7724,
        startno: 86,
        firstName: "Dylan",
        lastName: "Van Baarle",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1992-05-21",
        teamId: 21827,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
      },
      12493: {
        id: 12493,
        startno: 115,
        firstName: "Fabian",
        lastName: "Lienhard",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1993-09-03",
        teamId: 21814,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
      },
      95151: {
        id: 95151,
        startno: 104,
        firstName: "Quinn",
        lastName: "Simmons",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "2001-05-08",
        teamId: 21837,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      19274: {
        id: 19274,
        startno: 13,
        firstName: "Victor",
        lastName: "Campenaerts",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1991-10-28",
        teamId: 21820,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
      },
      65033: {
        id: 65033,
        startno: 246,
        firstName: "Arne",
        lastName: "Marit",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1999-01-21",
        teamId: 23398,
        teamName: "Sport Vlaanderen-Baloise",
        stillInTheRace: "Y",
      },
      12481: {
        id: 12481,
        startno: 45,
        firstName: "Florian",
        lastName: "S\u00c3\u00a9n\u00c3\u00a9chal",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1993-07-10",
        teamId: 21809,
        teamName: "Quick-Step Alpha Vinyl Team",
        stillInTheRace: "Y",
      },
      9746: {
        id: 9746,
        startno: 202,
        firstName: "Pascal",
        lastName: "Ackermann",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1994-01-17",
        teamId: 21839,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
      },
      31506: {
        id: 31506,
        startno: 237,
        firstName: "Bas",
        lastName: "Tietema",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1995-01-29",
        teamId: 23137,
        teamName: "Bingoal Pauwels Sauces WB",
        stillInTheRace: "Y",
      },
      18258: {
        id: 18258,
        startno: 51,
        firstName: "Wout",
        lastName: "van Aert",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1994-09-15",
        teamId: 21830,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
      },
      20104: {
        id: 20104,
        startno: 152,
        firstName: "Benjamin",
        lastName: "Declercq",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1994-02-04",
        teamId: 21865,
        teamName: "Team Ark\u00c3\u00a9a-Samsic",
        stillInTheRace: "Y",
      },
      63050: {
        id: 63050,
        startno: 153,
        firstName: "Matis",
        lastName: "Louvel",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1999-07-19",
        teamId: 21865,
        teamName: "Team Ark\u00c3\u00a9a-Samsic",
        stillInTheRace: "Y",
      },
      14717: {
        id: 14717,
        startno: 37,
        firstName: "Davide",
        lastName: "Martinelli",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1993-05-31",
        teamId: 21803,
        teamName: "Astana Qazaqstan Team",
        stillInTheRace: "Y",
      },
      27230: {
        id: 27230,
        startno: 182,
        firstName: "Kristoffer",
        lastName: "Halvorsen",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1996-04-13",
        teamId: 21843,
        teamName: "Uno-X Pro Cycling Team",
        stillInTheRace: "Y",
      },
      45364: {
        id: 45364,
        startno: 126,
        firstName: "Jordi",
        lastName: "Meeus",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1998-07-01",
        teamId: 22245,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
      },
      27121: {
        id: 27121,
        startno: 42,
        firstName: "Kasper",
        lastName: "Asgreen",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1995-02-08",
        teamId: 21809,
        teamName: "Quick-Step Alpha Vinyl Team",
        stillInTheRace: "Y",
      },
      16143: {
        id: 16143,
        startno: 226,
        firstName: "Julien",
        lastName: "Morice",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1991-07-20",
        teamId: 21850,
        teamName: "B&B Hotels-KTM",
        stillInTheRace: "Y",
      },
      46186: {
        id: 46186,
        startno: 227,
        firstName: "Luca",
        lastName: "Mozzato",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1998-02-15",
        teamId: 21850,
        teamName: "B&B Hotels-KTM",
        stillInTheRace: "Y",
      },
      361: {
        id: 361,
        startno: 73,
        firstName: "Maciej",
        lastName: "Bodnar",
        nationCode: "POL",
        nationName: "Poland",
        birthDate: "1985-03-07",
        teamId: 21868,
        teamName: "TotalEnergies",
        stillInTheRace: "Y",
      },
      1987: {
        id: 1987,
        startno: 175,
        firstName: "Baptiste",
        lastName: "Planckaert",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1988-09-28",
        teamId: 21856,
        teamName: "Intermarch\u00c3\u00a9-Wanty-Gobert Mat\u00c3\u00a9riaux",
        stillInTheRace: "Y",
      },
      38481: {
        id: 38481,
        startno: 215,
        firstName: "Mathias",
        lastName: "Norsgaard",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1997-05-05",
        teamId: 21823,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      93347: {
        id: 93347,
        startno: 112,
        firstName: "Lewis",
        lastName: "Askey",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "2001-05-04",
        teamId: 21814,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
      },
      16672: {
        id: 16672,
        startno: 21,
        firstName: "Mathieu",
        lastName: "van der Poel",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1995-01-19",
        teamId: 21845,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
      },
      327: {
        id: 327,
        startno: 96,
        firstName: "Michael",
        lastName: "Sch\u00c3\u00a4r",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1986-09-29",
        teamId: 21801,
        teamName: "AG2R Citro\u00c3\u00abn Team",
        stillInTheRace: "Y",
      },
      37427: {
        id: 37427,
        startno: 92,
        firstName: "Stan",
        lastName: "Dewulf",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1997-12-20",
        teamId: 21801,
        teamName: "AG2R Citro\u00c3\u00abn Team",
        stillInTheRace: "Y",
      },
      45354: {
        id: 45354,
        startno: 242,
        firstName: "Ruben",
        lastName: "Apers",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1998-08-25",
        teamId: 23398,
        teamName: "Sport Vlaanderen-Baloise",
        stillInTheRace: "Y",
      },
      16506: {
        id: 16506,
        startno: 125,
        firstName: "Martin",
        lastName: "Laas",
        nationCode: "EST",
        nationName: "Estonia",
        birthDate: "1993-09-15",
        teamId: 22245,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
      },
      1013: {
        id: 1013,
        startno: 141,
        firstName: "John",
        lastName: "Degenkolb",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1989-01-07",
        teamId: 21833,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      64651: {
        id: 64651,
        startno: 145,
        firstName: "Tim",
        lastName: "Naberman",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1999-05-11",
        teamId: 21833,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      38169: {
        id: 38169,
        startno: 214,
        firstName: "Johan",
        lastName: "Jacobs",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1997-03-01",
        teamId: 21823,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      8377: {
        id: 8377,
        startno: 23,
        firstName: "Tim",
        lastName: "Merlier",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1992-10-30",
        teamId: 21845,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
      },
      174: {
        id: 174,
        startno: 14,
        firstName: "Philippe",
        lastName: "Gilbert",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1982-07-05",
        teamId: 21820,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
      },
      16637: {
        id: 16637,
        startno: 76,
        firstName: "Anthony",
        lastName: "Turgis",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1994-05-16",
        teamId: 21868,
        teamName: "TotalEnergies",
        stillInTheRace: "Y",
      },
      49917: {
        id: 49917,
        startno: 203,
        firstName: "Felix",
        lastName: "Gro\u00c3\u009f",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1998-09-04",
        teamId: 21839,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
      },
      31: {
        id: 31,
        startno: 72,
        firstName: "Edvald",
        lastName: "Boasson-Hagen",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1987-05-17",
        teamId: 21868,
        teamName: "TotalEnergies",
        stillInTheRace: "Y",
      },
      16789: {
        id: 16789,
        startno: 77,
        firstName: "Dries",
        lastName: "van Gestel",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1994-09-30",
        teamId: 21868,
        teamName: "TotalEnergies",
        stillInTheRace: "Y",
      },
      1937: {
        id: 1937,
        startno: 61,
        firstName: "Guillaume",
        lastName: "Boivin",
        nationCode: "CAN",
        nationName: "Canada",
        birthDate: "1989-05-25",
        teamId: 21818,
        teamName: "Israel-Premier Tech",
        stillInTheRace: "Y",
      },
      32609: {
        id: 32609,
        startno: 221,
        firstName: "J\u00c3\u00a9r\u00c3\u00a9my",
        lastName: "Lecroq",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1995-04-07",
        teamId: 21850,
        teamName: "B&B Hotels-KTM",
        stillInTheRace: "Y",
      },
      777: {
        id: 777,
        startno: 74,
        firstName: "Daniel",
        lastName: "Oss",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1987-01-13",
        teamId: 21868,
        teamName: "TotalEnergies",
        stillInTheRace: "Y",
      },
      77596: {
        id: 77596,
        startno: 187,
        firstName: "S\u00c3\u00b8ren",
        lastName: "W\u00c3\u00a6renskjold",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "2000-03-12",
        teamId: 21843,
        teamName: "Uno-X Pro Cycling Team",
        stillInTheRace: "Y",
      },
      19254: {
        id: 19254,
        startno: 151,
        firstName: "Amaury",
        lastName: "Capiot",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1993-06-25",
        teamId: 21865,
        teamName: "Team Ark\u00c3\u00a9a-Samsic",
        stillInTheRace: "Y",
      },
      6100: {
        id: 6100,
        startno: 183,
        firstName: "Lasse",
        lastName: "Norman Hansen",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1992-02-11",
        teamId: 21843,
        teamName: "Uno-X Pro Cycling Team",
        stillInTheRace: "Y",
      },
      38940: {
        id: 38940,
        startno: 133,
        firstName: "Eddy",
        lastName: "Fin\u00c3\u00a9",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1997-11-20",
        teamId: 22290,
        teamName: "Cofidis",
        stillInTheRace: "Y",
      },
      163: {
        id: 163,
        startno: 6,
        firstName: "Yukiya",
        lastName: "Arashiro",
        nationCode: "JPN",
        nationName: "Japan",
        birthDate: "1984-09-22",
        teamId: 21804,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
      },
      38000: {
        id: 38000,
        startno: 232,
        firstName: "Stanislaw",
        lastName: "Aniolkowski",
        nationCode: "POL",
        nationName: "Poland",
        birthDate: "1997-01-20",
        teamId: 23137,
        teamName: "Bingoal Pauwels Sauces WB",
        stillInTheRace: "Y",
      },
      2327: {
        id: 2327,
        startno: 191,
        firstName: "Luke",
        lastName: "Durbridge",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1991-04-09",
        teamId: 22480,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
      },
      865: {
        id: 865,
        startno: 234,
        firstName: "Timothy",
        lastName: "Dupont",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1987-11-01",
        teamId: 23137,
        teamName: "Bingoal Pauwels Sauces WB",
        stillInTheRace: "Y",
      },
      46167: {
        id: 46167,
        startno: 212,
        firstName: "I\u00c3\u00b1igo",
        lastName: "Elosegui",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1998-03-06",
        teamId: 21823,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      6414: {
        id: 6414,
        startno: 5,
        firstName: "Jasha",
        lastName: "S\u00c3\u00bctterlin",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1992-11-04",
        teamId: 21804,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
      },
      77102: {
        id: 77102,
        startno: 56,
        firstName: "Mick ",
        lastName: "Van Dijke",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "2000-03-15",
        teamId: 21830,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
      },
      16724: {
        id: 16724,
        startno: 111,
        firstName: "Stefan",
        lastName: "K\u00c3\u00bcng",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1993-11-16",
        teamId: 21814,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
      },
      102733: {
        id: 102733,
        startno: 84,
        firstName: "Magnus",
        lastName: "Sheffield",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "2002-04-19",
        teamId: 21827,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
      },
      37466: {
        id: 37466,
        startno: 12,
        firstName: "C\u00c3\u00a9dric",
        lastName: "Beullens",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1997-01-27",
        teamId: 21820,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
      },
      47120: {
        id: 47120,
        startno: 245,
        firstName: "Sander",
        lastName: "De Pestel",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1998-10-11",
        teamId: 23398,
        teamName: "Sport Vlaanderen-Baloise",
        stillInTheRace: "Y",
      },
      27304: {
        id: 27304,
        startno: 97,
        firstName: "Damien",
        lastName: "Touz\u00c3\u00a9",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1996-07-07",
        teamId: 21801,
        teamName: "AG2R Citro\u00c3\u00abn Team",
        stillInTheRace: "Y",
      },
      46161: {
        id: 46161,
        startno: 165,
        firstName: "Jonas",
        lastName: "Rutsch",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1998-01-24",
        teamId: 21812,
        teamName: "EF Education-EasyPost",
        stillInTheRace: "Y",
      },
      10802: {
        id: 10802,
        startno: 176,
        firstName: "Taco",
        lastName: "van der Hoorn",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1993-12-04",
        teamId: 21856,
        teamName: "Intermarch\u00c3\u00a9-Wanty-Gobert Mat\u00c3\u00a9riaux",
        stillInTheRace: "Y",
      },
      1970: {
        id: 1970,
        startno: 44,
        firstName: "Tim",
        lastName: "Declercq",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1989-03-21",
        teamId: 21809,
        teamName: "Quick-Step Alpha Vinyl Team",
        stillInTheRace: "Y",
      },
      2317: {
        id: 2317,
        startno: 26,
        firstName: "Julien",
        lastName: "Vermote",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1989-07-26",
        teamId: 21845,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
      },
      70759: {
        id: 70759,
        startno: 134,
        firstName: "Alexis",
        lastName: "Renard",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1999-06-01",
        teamId: 22290,
        teamName: "Cofidis",
        stillInTheRace: "Y",
      },
      1580: {
        id: 1580,
        startno: 35,
        firstName: "Fabio",
        lastName: "Felline",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1990-03-29",
        teamId: 21803,
        teamName: "Astana Qazaqstan Team",
        stillInTheRace: "Y",
      },
      37303: {
        id: 37303,
        startno: 181,
        firstName: "Rasmus",
        lastName: "Tiller",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1996-07-28",
        teamId: 21843,
        teamName: "Uno-X Pro Cycling Team",
        stillInTheRace: "Y",
      },
      20945: {
        id: 20945,
        startno: 107,
        firstName: "Otto",
        lastName: "Vergaerde",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1994-07-15",
        teamId: 21837,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      4669: {
        id: 4669,
        startno: 47,
        firstName: "Zdenek",
        lastName: "Stybar",
        nationCode: "CZE",
        nationName: "Czech Republic",
        birthDate: "1985-12-11",
        teamId: 21809,
        teamName: "Quick-Step Alpha Vinyl Team",
        stillInTheRace: "Y",
      },
      170: {
        id: 170,
        startno: 225,
        firstName: "Cyril",
        lastName: "Lemoine",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1983-03-03",
        teamId: 21850,
        teamName: "B&B Hotels-KTM",
        stillInTheRace: "Y",
      },
      16803: {
        id: 16803,
        startno: 207,
        firstName: "Oliviero",
        lastName: "Troia",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1994-09-01",
        teamId: 21839,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
      },
      2212: {
        id: 2212,
        startno: 193,
        firstName: "Jack",
        lastName: "Bauer",
        nationCode: "NZL",
        nationName: "New Zealand",
        birthDate: "1985-04-07",
        teamId: 22480,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
      },
      2146: {
        id: 2146,
        startno: 172,
        firstName: "Dimitri",
        lastName: "Claeys",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1987-06-18",
        teamId: 21856,
        teamName: "Intermarch\u00c3\u00a9-Wanty-Gobert Mat\u00c3\u00a9riaux",
        stillInTheRace: "Y",
      },
      37219: {
        id: 37219,
        startno: 147,
        firstName: "Casper",
        lastName: "Pedersen",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1996-03-15",
        teamId: 21833,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      71217: {
        id: 71217,
        startno: 85,
        firstName: "Ben",
        lastName: "Turner",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1999-05-29",
        teamId: 21827,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
      },
      8740: {
        id: 8740,
        startno: 103,
        firstName: "Alex",
        lastName: "Kirsch",
        nationCode: "LUX",
        nationName: "Luxembourg",
        birthDate: "1992-06-12",
        teamId: 21837,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      9234: {
        id: 9234,
        startno: 197,
        firstName: "Luka",
        lastName: "Mezgec",
        nationCode: "SLO",
        nationName: "Slovenia",
        birthDate: "1988-06-27",
        teamId: 22480,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
      },
      150: {
        id: 150,
        startno: 91,
        firstName: "Greg",
        lastName: "Van Avermaet",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1985-05-17",
        teamId: 21801,
        teamName: "AG2R Citro\u00c3\u00abn Team",
        stillInTheRace: "Y",
      },
      53490: {
        id: 53490,
        startno: 241,
        firstName: "Rune",
        lastName: "Herregodts",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1998-07-27",
        teamId: 23398,
        teamName: "Sport Vlaanderen-Baloise",
        stillInTheRace: "Y",
      },
      7011: {
        id: 7011,
        startno: 217,
        firstName: "Albert",
        lastName: "Torres",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1990-04-26",
        teamId: 21823,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      9349: {
        id: 9349,
        startno: 124,
        firstName: "Jonas",
        lastName: "Koch",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1993-06-25",
        teamId: 22245,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
      },
      45602: {
        id: 45602,
        startno: 127,
        firstName: "Ide",
        lastName: "Schelling",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1998-02-06",
        teamId: 22245,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
      },
      80271: {
        id: 80271,
        startno: 192,
        firstName: "Alexandre",
        lastName: "Balmer",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "2000-05-04",
        teamId: 22480,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
      },
      53489: {
        id: 53489,
        startno: 17,
        firstName: "Brent",
        lastName: "Van Moer",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1998-01-12",
        teamId: 21820,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
      },
      7580: {
        id: 7580,
        startno: 94,
        firstName: "Gijs",
        lastName: "Van Hoecke",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1991-11-12",
        teamId: 21801,
        teamName: "AG2R Citro\u00c3\u00abn Team",
        stillInTheRace: "Y",
      },
      2326: {
        id: 2326,
        startno: 75,
        firstName: "Geoffrey",
        lastName: "Soupe",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1988-03-22",
        teamId: 21868,
        teamName: "TotalEnergies",
        stillInTheRace: "Y",
      },
      840: {
        id: 840,
        startno: 16,
        firstName: "Roger",
        lastName: "Kluge",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1986-02-05",
        teamId: 21820,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
      },
      29: {
        id: 29,
        startno: 71,
        firstName: "Niki",
        lastName: "Terpstra",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1984-05-18",
        teamId: 21868,
        teamName: "TotalEnergies",
        stillInTheRace: "Y",
      },
      16787: {
        id: 16787,
        startno: 62,
        firstName: "Jenthe",
        lastName: "Biermans",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1995-10-30",
        teamId: 21818,
        teamName: "Israel-Premier Tech",
        stillInTheRace: "Y",
      },
      20822: {
        id: 20822,
        startno: 233,
        firstName: "Arjen",
        lastName: "Livyns",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1994-09-01",
        teamId: 23137,
        teamName: "Bingoal Pauwels Sauces WB",
        stillInTheRace: "Y",
      },
      3045: {
        id: 3045,
        startno: 174,
        firstName: "Adrien",
        lastName: "Petit",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1990-09-26",
        teamId: 21856,
        teamName: "Intermarch\u00c3\u00a9-Wanty-Gobert Mat\u00c3\u00a9riaux",
        stillInTheRace: "Y",
      },
      27097: {
        id: 27097,
        startno: 52,
        firstName: "Edoardo",
        lastName: "Affini",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1996-06-24",
        teamId: 21830,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
      },
      27232: {
        id: 27232,
        startno: 186,
        firstName: "Anders",
        lastName: "Skaarseth",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1995-05-07",
        teamId: 21843,
        teamName: "Uno-X Pro Cycling Team",
        stillInTheRace: "Y",
      },
    },
  }

  const race = "Paris Roubaix"
  const raceID = 8

  return (
    <RacePageOneDay2022
      title={`${race} 2022`}
      startlistLocal={startlist}
      race={race}
      raceID={raceID}
    />
  )
}

export default Race2022
